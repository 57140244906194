.page-body {
    height: 100%;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    width: 100%;
    position: relative;
    z-index: 10;

    h4 {
        align-self: center;
        flex-grow: 1;
    }

    a {
        align-self: center;
    }
}
