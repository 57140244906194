.table {
    width: 100%;
    height: 100%;
    min-width: 0;
    margin-left: auto;
    margin-right: auto;
    /* position: relative needed for proper animation positioning */
    position: relative;
}

.table-cards-pot-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 20%;
    width: 20%;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
}
