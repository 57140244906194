.page-container-grid {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    display: grid;
    grid-template-rows: [page-top] .1fr [chat-top] .1fr [navbar-top] .05fr [content-top] .75fr [footer-top] .05fr [footer-bottom];
    grid-template-columns: [page-left] .15fr [header-content-left] .7fr [chat-left] .15fr [page-right];

    .dead-space {
        grid-area: page-top / page-left / footer-top / header-content-left;
    }

    .header {
        height: 100%;
        grid-area: page-top / header-content-left / navbar-top / chat-left;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-items: center;
        justify-content: center;
        padding: 10px;
    }

    .navbar {
        height: 100%;
        grid-area: navbar-top / header-content-left / content-top / chat-left;
    }

    .content {
        height: 100%;
        display: inline-block;
        grid-area: content-top / header-content-left / footer-top / chat-left;
        overflow: auto;
        padding: 2rem;
    }

    .buttons-container {
        height: 100%;
        grid-area: page-top / chat-left / chat-top / page-right;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }

    .chat {
        grid-area: chat-top / chat-left / footer-top / page-right;
    }

    .footer {
        grid-area: footer-top / page-left / page-bottom / page-right;

        .items {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            align-items: flex-end;
            justify-content: flex-end;
        }
    }
}
