@import "../../theme";

.register-page {
    padding: 2%;
    margin: auto;
    height: 100vh;
    background-color: #f9f9f9;
}

[data-theme='dark'] .register-page {
    background-color: $color-dark-background;
}

.logo {
    display: flex;
    justify-content: center;
}

.register-form {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.register-button {
    justify-content: center;
    padding-top: 10px;

    button {
        width: 50%;
    }
}

