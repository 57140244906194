.chat-with-dealer-controls {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    max-height: 100%;
}

.arrows-container {
    border: 1px solid black;
    background-color: grey;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
