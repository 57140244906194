.transition-component {
    position: absolute;
    will-change: transform, width, height, opacity;
}

.transition-component-data {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}
