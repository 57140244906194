.page-container-grid {
    position: relative;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    display: grid;
    grid-template-rows: [page-top] minmax(2%, .02fr) [table-top] 78% [information-top] 20% [page-bottom];
    grid-template-columns: [page-left] 1fr [actions-left] 1fr [information-left] 1fr [page-right];

    .leave {
        grid-area: page-top / page-left / table-top / page-right;
        z-index: 9;
    }

    .table {
        grid-area: table-top / page-left / information-top / page-right;
        width: 100%;
        height: 100%;
        min-width: 0;
        background-position: center;
        background-size: 90% 85%;
        background-repeat: no-repeat;
        margin-left: auto;
        margin-right: auto;
    }

    .chat {
        grid-area: information-top / page-left / page-bottom / actions-left;
        width: 100%;
        height: 100%;
        min-width: 0;
    }

    .actions {
        grid-area: information-top / actions-left / page-bottom / information-left;
        display: flex;
        flex-direction: column;
    }

    .information {
        grid-area:  information-top / information-left / page-bottom / page-right;
        display: flex;
        flex-direction: column;
        overflow-wrap: anywhere;
        overflow-y: auto;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
    flex: 1;
}
