.chat-box-with-actions-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.chat-box {
    border: 1px solid black;
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-wrap: anywhere;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: darkslategray;
}

.button-with-form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.player-message {
    width: 100%;
    align-items: flex-start;
    float: right;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
}

.dealer-message {
    width: 100%;
    align-items: flex-start;
    float: right;
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;

    &-cards {
        max-height: 3rem;
        border-radius: 5px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;

        &-container {
            display: flex;
        }
    }
}
