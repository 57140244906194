.transition-component {
    position: absolute;
    will-change: transform, width, height, opacity;
}

.transition-component-data {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}

.player-info-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
}

.player-rounded-box {
    width: 100%;
    border: 1px solid black;
    border-radius: 8px;
    align-items: center;
    display: flex;
    flex-direction: column;

    &-chips {
        width: 100%;
        border: 1px solid black;
        background: black;
        justify-content: center;
        display: flex;
        flex: 1;

    }
}

.player-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card {
    max-height: 6rem;
    border-radius: 5px;
}
