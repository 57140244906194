.navbar {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.spacer {
    display: flex;
    flex-direction: row;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
