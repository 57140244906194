.table-cards-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 0;
    height: 65%;
    width: 100%;
    transform: translate(-50%, 0%);
}

.card {
    max-height: 6rem;
    height: 100%;
    border-radius: 5px;
    margin: 0.2rem;
}
