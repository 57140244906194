.pot-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: absolute;
    height: 35%;
    width: 100%;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
}

.pot {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
